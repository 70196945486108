import React from 'react';
import { NavLink } from 'react-router-dom';

import tw, { styled } from 'twin.macro';

const Wrapper = styled.div`
  ${tw`fixed bottom-0 z-30 grid w-full grid-cols-5 bg-white/90 pb-3 backdrop-blur-md px-[4vw] shadow-[0 25px 50px 12px rgb(0 0 0 / 0.16)] md:(hidden px-[6vw])`}
`;

const NavLinkWrapper = styled.div`
  a {
    ${tw`block border-white border-t-2`}
  }

  a.active {
    ${tw`border-blue-500!`}
  }

  a.active > div {
    ${tw`text-blue-600`}
  }
`;

const MenuItem = styled.div`
  ${tw`w-full cursor-pointer space-y-1 py-2.5 text-center font-semibold text-gray-500 text-xs md:px-2`}
`;

const Icon = styled.svg`
  ${tw`m-auto stroke-2`}
`;

export default function Navbar() {
  return (
    <Wrapper>
      <NavLinkWrapper>
        <NavLink
          to="/"
          className={({ isActive }) => (isActive ? 'active' : '')}
        >
          <MenuItem>
            <Icon
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path d="M21.21 15.89A10 10 0 1 1 8 2.83"></path>
              <path d="M22 12A10 10 0 0 0 12 2v10z"></path>
            </Icon>
            <p>Översikt</p>
          </MenuItem>
        </NavLink>
      </NavLinkWrapper>
      <NavLinkWrapper>
        <NavLink
          to="/holdings"
          className={({ isActive }) => (isActive ? 'active' : '')}
        >
          <MenuItem>
            <Icon
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect>
              <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path>
            </Icon>
            <p>Portfölj</p>
          </MenuItem>
        </NavLink>
      </NavLinkWrapper>
      <NavLinkWrapper>
        <MenuItem tw="border-0"> </MenuItem>
      </NavLinkWrapper>
      <NavLinkWrapper>
        <NavLink
          to="/reports"
          className={({ isActive }) => (isActive ? 'active' : '')}
        >
          <MenuItem>
            <Icon
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
            >
              <path d="M4 22h16a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H8a2 2 0 0 0-2 2v16a2 2 0 0 1-2 2Zm0 0a2 2 0 0 1-2-2v-9c0-1.1.9-2 2-2h2"></path>
              <path d="M18 14h-8"></path>
              <path d="M15 18h-5"></path>
              <path d="M10 6h8v4h-8V6Z"></path>
            </Icon>
            <p>Rapporter</p>
          </MenuItem>
        </NavLink>
      </NavLinkWrapper>
      <NavLinkWrapper>
        <NavLink
          to="/blogs"
          className={({ isActive }) => (isActive ? 'active' : '')}
        >
          <MenuItem>
            <Icon
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
            >
              <line x1="18" y1="2" x2="22" y2="6"></line>
              <path d="M7.5 20.5 19 9l-4-4L3.5 16.5 2 22z"></path>
            </Icon>
            <p>Bloggar</p>
          </MenuItem>
        </NavLink>
      </NavLinkWrapper>
    </Wrapper>
  );
}
