import parse from 'html-react-parser';
import React from 'react';
import tw, { styled } from 'twin.macro';

import NewsData from '../news.json';

import { Helmet } from 'react-helmet';

import Layout from '../components/Layout';

const title = 'Nyheter | Aktieutdelningar';
const desc =
      'Här hittar du nyheter och uppdateringar som gäller plattformen.';

const News = () => {
  const Content = styled.div`
    ul {
      ${tw`ml-8 mb-4! list-disc`}
    }
  `;
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={desc} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={desc} />
        <meta property="og:url" content={window.location.href} />
        {/* <meta property="og:image" content={NextoryMetaImg} /> */}
        <meta name="twitter:title" content={title} />
      </Helmet>
      <Layout>
        <main tw="mt-0 h-full w-full overflow-y-auto py-8 pb-24 max-md:px-4">
          <div className="content" tw="m-auto max-w-xl space-y-3 text-base">
            <p tw="mb-8">
              <a href="/">Tillbaka till Aktieutdelningar</a>
            </p>
            <h1 tw="pb-2">
              <span role="img" aria-label="emoji">
                🧻
              </span>
              <span>Nyheter</span>
            </h1>
            {NewsData.map((item, index) => {
              return (
                <article key={index} tw="mt-0! border-gray-200 border-b py-6">
                  <header tw="space-y-3">
                    <span tw="block">{item.date}</span>
                    <h2 tw="text-2xl!">{item.title}</h2>
                  </header>
                  {item.content && (
                    <Content tw="mt-4 space-y-3">{parse(item.content)}</Content>
                  )}
                </article>
              );
            })}
          </div>
        </main>
      </Layout>
    </>
  );
};

export default News;
