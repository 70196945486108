import React from 'react';
import { formatNumber } from '../../helpers/index';
import tw from 'twin.macro';

const Parent = tw.div`inline-flex w-1/2 flex-col bg-white font-mono xl:(w-full flex-row)`;
const Child = tw.div`w-full border-b`;
const Cell = tw.div`flex-1 border-gray-200 py-3 text-center xl:(border-r py-4)`;
const Head = tw.div`border-gray-200 border-r bg-gray-50 py-3 text-center font-extrabold`;

function RenderTableItem({ items }) {
  return (
    <>
      <Parent>
        {items.map((item, index) => (
          <Child key={index}>
            <Head>
              <p>{item.name}</p>
            </Head>
          </Child>
        ))}
      </Parent>

      <Parent>
        {items.map((item, index) => (
          <Child key={index}>
            <Cell>
              <p>{`${formatNumber(item.value)}:-`}</p>
            </Cell>
          </Child>
        ))}
      </Parent>
    </>
  );
}

class YearTable extends React.PureComponent {
  render() {
    const halfYears = [{ name: 'Första halvåret', value: this.props.data.half1 }, { name: 'Andra halvåret', value: this.props.data.half2 }];
    const quarters = [
      { name: 'Q1', value: this.props.data.q1 },
      { name: 'Q2', value: this.props.data.q2 },
      { name: 'Q3', value: this.props.data.q3 },
      { name: 'Q4', value: this.props.data.q4 },
    ];
    const monthNames = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "okt", "nov", "dec"]
      .map((month) => ({ name: month.charAt(0).toUpperCase() + month.slice(1), value: this.props.data[month] }));

    return (
      <div tw="w-full border-t">
        <RenderTableItem items={halfYears} />
        <RenderTableItem items={quarters} />
        <RenderTableItem items={monthNames} />
      </div>
    );
  }
}

export default YearTable;
