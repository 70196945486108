import React from 'react';
import { NavLink } from 'react-router-dom';

import tw, { styled } from 'twin.macro';

import PortfolioUpdate from '../Sidebar/PortfolioUpdate';

import { Dashboard, Briefcase, Newspaper, Edit2 } from 'lucide-react';

const ToolbarWrapper = styled.div(({ toolbarVisible }) => [
  tw`z-30 hidden h-full flex-col place-content-between border-gray-200 border-r md:flex`,

  toolbarVisible && tw`max-md:translate-x-0`,
]);

const NavLinkWrapper = styled.div`
  a.active > div {
    ${tw`text-blue-600`}
  }
`;

const MenuItem = styled.div`
  ${tw`w-full cursor-pointer space-y-2 border-b border-b-gray-200 py-4 px-4 text-center font-semibold text-xs text-gray-500 md:px-2`}

  &.add {
    ${tw`border-b-0 bg-blue-500 text-white`}
  }
`;

const Icon = styled.svg`
  ${tw`m-auto stroke-2`}
`;

export default function Toolbar() {
  return (
    <ToolbarWrapper>
      <div>
        <NavLinkWrapper>
          <NavLink
            to="/"
            className={({ isActive }) => (isActive ? 'active' : '')}
          >
            <MenuItem>
              <Icon
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M21.21 15.89A10 10 0 1 1 8 2.83"></path>
                <path d="M22 12A10 10 0 0 0 12 2v10z"></path>
              </Icon>
              <p tw="hidden md:block">Översikt</p>
            </MenuItem>
          </NavLink>
        </NavLinkWrapper>
        <NavLinkWrapper>
          <NavLink
            to="/holdings"
            className={({ isActive }) => (isActive ? 'active' : '')}
          >
            <MenuItem>
              <Icon
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect>
                <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path>
              </Icon>
              <p tw="hidden md:block">Portfölj</p>
            </MenuItem>
          </NavLink>
        </NavLinkWrapper>

        <NavLinkWrapper>
          <NavLink
            to="/reports"
            className={({ isActive }) => (isActive ? 'active' : '')}
          >
            <MenuItem>
              <Icon
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
              >
                <path d="M4 22h16a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H8a2 2 0 0 0-2 2v16a2 2 0 0 1-2 2Zm0 0a2 2 0 0 1-2-2v-9c0-1.1.9-2 2-2h2"></path>
                <path d="M18 14h-8"></path>
                <path d="M15 18h-5"></path>
                <path d="M10 6h8v4h-8V6Z"></path>
              </Icon>
              <p tw="hidden md:block">Rapporter</p>
            </MenuItem>
          </NavLink>
        </NavLinkWrapper>
        <NavLinkWrapper>
          <NavLink
            to="/toplist"
            className={({ isActive }) => (isActive ? 'active' : '')}
          >
            <MenuItem>
              <Icon
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
              >
                <line x1="18" y1="20" x2="18" y2="10"></line>
                <line x1="12" y1="20" x2="12" y2="4"></line>
                <line x1="6" y1="20" x2="6" y2="14"></line>
              </Icon>
              <p tw="hidden md:block">Topplista</p>
            </MenuItem>
          </NavLink>
        </NavLinkWrapper>
        <NavLinkWrapper>
          <NavLink
            to="/blogs"
            className={({ isActive }) => (isActive ? 'active' : '')}
          >
            <MenuItem>
              <Icon
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
              >
                <line x1="18" y1="2" x2="22" y2="6"></line>
                <path d="M7.5 20.5 19 9l-4-4L3.5 16.5 2 22z"></path>
              </Icon>
              <p tw="hidden md:block">Bloggar</p>
            </MenuItem>
          </NavLink>
        </NavLinkWrapper>
      </div>
      <div>
        <NavLinkWrapper>
          <MenuItem tw="m-0 cursor-default border-t border-b-0 p-0!">
            <PortfolioUpdate small>
              <p tw="hidden md:block">Uppdatera</p>
            </PortfolioUpdate>
          </MenuItem>
        </NavLinkWrapper>
      </div>
    </ToolbarWrapper>
  );
}
