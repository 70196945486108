import React from 'react';
import { Helmet } from 'react-helmet';

import Layout from '../components/Layout';
import Main from '../components/Overview';
import Sidebar from '../components/Sidebar';
import Toolbar from '../components/Toolbar';

const title = 'Aktieutdelningar';
const desc = 'Bygg din aktieportfölj och få en överblick över dina utdelningar';

export default function OverviewView() {
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={desc} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={desc} />
        <meta property="og:url" content={window.location.href} />
        {/* <meta property="og:image" content={NextoryMetaImg} /> */}
        <meta name="twitter:title" content={title} />
      </Helmet>

      <Sidebar mobile />
      <Layout>
        <Toolbar />
        <Sidebar desktop />
        <Main />
      </Layout>
    </>
  );
}
