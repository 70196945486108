import axios from 'axios';
import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import Plausible from 'plausible-tracker';

import tw from 'twin.macro';

const Parent = tw.div`flex w-full flex-wrap place-content-start border-gray-200 border-t bg-white [:first-of-type]:border-t-0`;
const Child = tw.div`w-full`;
const Cell = tw.div`grid grid-cols-12 border-0 border-gray-200 text-center font-mono`;
const Head = tw.div`border-gray-200 border-b bg-gray-50 px-4 py-2.5 font-extrabold`;

const { trackEvent } = Plausible({
  domain: 'aktieutdelningar.now.sh',
  apiHost: 'https://ping.arvdsn.co',
});

var stringToColour = function(str) {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  var colour = '#';
  for (var i = 0; i < 3; i++) {
    var value = (hash >> (i * 8)) & 0xFF;
    colour += ('00' + value.toString(16)).substr(-2);
  }
  return colour;
}

const BlogPostTable = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [feed, setFeed] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get(
          'https://aktieutdelningar-g3zg0.ams3.digitaloceanspaces.com/blogs.json'
        );

        const feed = data.feed;
        setFeed(feed);
        setIsLoading(false);
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, []);

  return (
    <div tw="block w-full">
      {isLoading ? (
        <Parent tw="border-gray-200 border-t">
          <Child>
            <Cell>
              <div tw="col-span-12 flex items-center border-b px-4 py-4 text-left">
                Laddar senaste blogginläggen...
              </div>
            </Cell>
          </Child>
        </Parent>
      ) : (
        <>
          {Object.entries(
            feed.reduce((acc, post) => {
              const date = dayjs(post.published).format('YYYY-MM-DD');
              if (date in acc) {
                acc[date].push({ post });
              } else {
                acc[date] = [{ post }];
              }
              return acc;
            }, {})
          ).map(([date, posts], i) => {
            return (
              <Parent key={i} tw="mb-4">
                <Child>
                  <Head tw="flex place-content-between items-center">
                    <p>
                      {date === dayjs().format('YYYY-MM-DD')
                        ? 'Idag'
                        : date ===
                          dayjs().subtract(1, 'day').format('YYYY-MM-DD')
                          ? 'Igår'
                          : date}
                    </p>
                  </Head>
                </Child>
                <Child>
                  {posts
                    .filter(entry => entry.post.link !== undefined)
                    .map((entry, index) => {
                      const post = entry.post;

                      return (
                        <React.Fragment key={index}>
                          <div tw="col-span-6 flex flex-col space-y-1 border-b px-4 py-2 text-left">
                            <div tw="flex items-center space-x-1.5">
                              <span tw="h-2 w-2 block rounded-full" style={{ backgroundColor: stringToColour(post.author) }}></span>
                              <span tw="font-medium text-black">{post.author}</span>
                            </div>
                            <div>
                              <a
                                tw="font-bold text-blue-600 text-base"
                                href={post.link}
                                target="_blank"
                                rel="noopener"
                                onClick={() => {
                                  trackEvent('Blog: Outbound', {
                                    props: {
                                      blog: post.author,
                                      url: post.link,
                                    },
                                  });
                                }}
                              >
                                {post.title}
                              </a>
                            </div>
                          </div>
                        </React.Fragment>
                      );
                    })}
                </Child>
              </Parent>
            );
          })}
        </>
      )}
    </div>
  );
};

export default BlogPostTable;
