import _ from 'lodash';
import React, { useState } from 'react';
import { formatNumber } from '../../helpers/index';

import tw from 'twin.macro';

import ChevronDown from '../../images/icons/ic_chevron_down.svg';
import ChevronUp from '../../images/icons/ic_chevron_up.svg';

import { ChevronLeft, ChevronRight } from 'lucide-react';

const Parent = tw.div`flex w-full flex-wrap place-content-start border-gray-200 border-t`;
const Child = tw.div`w-full`;
const Cell = tw.div`grid grid-cols-12 border-gray-200 border-t-0 text-center font-mono tracking-tight`;
const Head = tw.div`border-gray-200 border-b bg-gray-50 px-4 py-2.5 font-extrabold`;

const extractSector = (data, lastSectorNumber) => {
  const getLastSector = (n) => (stock) => {
    if (!stock.sectors) return 'N/A';
    return stock.sectors[stock.sectors.length - n];
  };
  const lastSector = getLastSector(lastSectorNumber);
  let sectorTotals = {};
  let totalInvestedCash = 0;

  data.map((stock) => {
    const investedCash =
      stock.price * parseFloat(stock.quantity.replace(',', '.'));
    const sector = lastSector(stock);
    if (sector === 'N/A') return null;
    totalInvestedCash += investedCash;

    if (sectorTotals[sector]) {
      sectorTotals[sector].investedCash += investedCash;
    } else {
      sectorTotals[sector] = {
        investedCash,
        percentage: 0,
      };
    }

    return sectorTotals;
  });

  Object.keys(sectorTotals).forEach((sector) => {
    sectorTotals[sector].percentage = (
      (sectorTotals[sector].investedCash / totalInvestedCash) *
      100
    ).toFixed(2);
  });

  return _.sortBy(Object.entries(sectorTotals), (a) => -a[1].percentage);
};

const SectorTable = ({ data }) => {
  const [expandState, setExpandState] = useState(1);
  const [sectorFilter, setSectorFilter] = useState(1);

  const sectorLevelOne = extractSector(data, 1);
  const sectorLevelTwo = extractSector(data, 2);
  const sectorLevelThree = extractSector(data, 3);

  const MobileLabel = tw.span`block md:hidden`;

  return (
    <div tw="mb-3 block w-full bg-white">
      <Parent>
        <Child>
          <Head tw="flex place-content-between items-center">
            <div tw="flex items-center space-x-4">
              <p
                tw="grow cursor-pointer"
                onClick={() => setExpandState(expandState === 1 ? 0 : 1)}
              >
                Branschfördelning
              </p>
              {data.length > 0 ? (
                <div tw="z-20 flex items-center space-x-1 rounded-full bg-gray-200 py-1 px-0 font-semibold shadow-inner">
                  <button
                    css={tw`py-0 px-1`}
                    onClick={() =>
                      setSectorFilter(Math.max(1, sectorFilter - 1))
                    }
                  >
                    <ChevronLeft color="black" size={24} />
                  </button>
                  <button
                    css={[
                      tw`rounded-full py-0 px-1.5`,
                      sectorFilter === 1 ? tw`bg-black text-white` : '',
                    ]}
                    onClick={() => setSectorFilter(1)}
                  >
                    1
                  </button>
                  <button
                    css={[
                      tw`rounded-full py-0 px-1.5`,
                      sectorFilter === 2 ? tw`bg-black text-white` : '',
                    ]}
                    onClick={() => setSectorFilter(2)}
                  >
                    2
                  </button>
                  <button
                    css={[
                      tw`rounded-full py-0 px-1.5`,
                      sectorFilter === 3 ? tw`bg-black text-white` : '',
                    ]}
                    onClick={() => setSectorFilter(3)}
                  >
                    3
                  </button>
                  <button
                    css={tw`py-0 px-1`}
                    onClick={() =>
                      setSectorFilter(Math.min(3, sectorFilter + 1))
                    }
                  >
                    <ChevronRight color="black" size={24} />{' '}
                  </button>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div
              tw="ml-4 cursor-pointer"
              onClick={() => setExpandState(expandState === 1 ? 0 : 1)}
            >
              {expandState ? (
                <img src={ChevronUp} alt="" />
              ) : (
                <img src={ChevronDown} alt="" />
              )}
            </div>
          </Head>
        </Child>
        <Child css={[expandState ? tw`block` : tw`hidden`]}>
          {data.length > 0 ? (
            <>
              <Cell tw="max-md:hidden">
                <div tw="col-span-6 border-b px-4 py-2 text-left text-gray-500">
                  Sektor
                </div>
                <div tw="col-span-3 border-gray-200 border-b border-l px-4 py-2 text-left text-gray-500 min-w-[110px]">
                  Kapital
                </div>
                <div tw="col-span-3 border-gray-200 border-b border-l px-4 py-2 text-left text-gray-500 min-w-[88px]">
                  Andel
                </div>
              </Cell>
              {sectorFilter === 1 &&
                sectorLevelOne.map(([sector, data], index) => (
                  <Cell
                    key={index}
                    tw="col-span-12 border-gray-200 border-b max-md:(space-y-3 py-3)"
                  >
                    <div tw="col-span-12 flex flex-row place-content-between px-4 md:(col-span-6 py-3)">
                      <MobileLabel>Sector</MobileLabel>
                      <span>{sector}</span>
                    </div>
                    <div tw="col-span-12 flex flex-row place-content-between px-4 md:(col-span-3 border-l py-3)">
                      <MobileLabel>Kapital</MobileLabel>
                      <span>{formatNumber(data.investedCash.toFixed(0))}:-</span>
                    </div>
                    <div tw="col-span-12 flex flex-row place-content-between px-4 md:(col-span-3 border-l py-3)">
                      <MobileLabel>Andel</MobileLabel>
                      <span tw="">{data.percentage}%</span>
                    </div>
                  </Cell>
                ))}
              {sectorFilter === 2 &&
                sectorLevelTwo.map(([sector, data], index) => (
                  <Cell
                    key={index}
                    tw="col-span-12 border-gray-200 border-b max-md:(space-y-3 py-3)"
                  >
                    <div tw="col-span-12 flex flex-row place-content-between px-4 md:(col-span-6 py-3)">
                      <MobileLabel>Sector</MobileLabel>
                      <span>{sector}</span>
                    </div>
                    <div tw="col-span-12 flex flex-row place-content-between px-4 md:(col-span-3 border-l py-3)">
                      <MobileLabel>Kapital</MobileLabel>
                      <span>{formatNumber(data.investedCash.toFixed(0))}:-</span>
                    </div>
                    <div tw="col-span-12 flex flex-row place-content-between px-4 md:(col-span-3 border-l py-3)">
                      <MobileLabel>Andel</MobileLabel>
                      <span tw="">{data.percentage}%</span>
                    </div>
                  </Cell>
                ))}
              {sectorFilter === 3 &&
                sectorLevelThree.map(([sector, data], index) => (
                  <Cell
                    key={index}
                    tw="col-span-12 border-gray-200 border-b max-md:(space-y-3 py-3)"
                  >
                    <div tw="col-span-12 flex flex-row place-content-between px-4 md:(col-span-6 py-3)">
                      <MobileLabel>Sector</MobileLabel>
                      <span>{sector}</span>
                    </div>
                    <div tw="col-span-12 flex flex-row place-content-between px-4 md:(col-span-3 border-l py-3)">
                      <MobileLabel>Kapital</MobileLabel>
                      <span>{formatNumber(data.investedCash.toFixed(0))}:-</span>
                    </div>
                    <div tw="col-span-12 flex flex-row place-content-between px-4 md:(col-span-3 border-l py-3)">
                      <MobileLabel>Andel</MobileLabel>
                      <span tw="">{data.percentage}%</span>
                    </div>
                  </Cell>
                ))}
            </>
          ) : (
            <Cell>
              <div tw="col-span-12 flex items-center border-b px-4 py-3 text-left">
                Du behöver lägga till värdeppaper för att kunna se sektordata
                kring ditt innehav. Du kan göra det i listan till vänster.
              </div>
            </Cell>
          )}
        </Child>
      </Parent>
    </div>
  );
};

export default SectorTable;
