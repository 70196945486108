import dayjs from 'dayjs';
import React, { useState } from 'react';
import { formatNumber } from '../../helpers/index';

import tw from 'twin.macro';

import FlagComponent from '../General/Flag';
import ChevronDown from '../../images/icons/ic_chevron_down.svg';
import ChevronUp from '../../images/icons/ic_chevron_up.svg';

const Parent = tw.div`flex w-full flex-wrap place-content-start bg-white [:first-of-type]:border-t-0`;
const Child = tw.div`w-full`;
const Cell = tw.div`grid grid-cols-12 border-gray-200 border-t-0 font-mono`;
const Head = tw.div`border-gray-200 border-b bg-gray-50 px-4 py-2.5 font-extrabold`;

function formatDate(date) {
  const today = dayjs().startOf('day');
  const eventDate = dayjs(date);
  if (eventDate.isBefore(today.add(14, 'day'))) {
    const diff = eventDate.diff(today, 'day');
    return (
      <>
        <p>{date}</p>
        {diff > 0 ? (
          <p tw="font-semibold text-blue-600">Om {diff} dagar</p>
        ) : diff === 0 ? (
          <p tw="font-semibold text-blue-600">Idag</p>
        ) : (
          <p tw="text-gray-500">{Math.abs(diff)} dagar sedan</p>
        )}
      </>
    );
  }
  return eventDate.format('YYYY-MM-DD');
}

function sortEvents(data, sortFunction) {
  return data
    .filter((item) => item.dividends)
    .reduce((acc, item) => {
      return acc.concat(
        item.dividends.map((dividend) => ({ ...dividend, api_id: item.api_id }))
      );
    }, [])
    .sort(sortFunction);
}

function getEventType(eventType) {
  switch (eventType) {
    case 'ORDINARY':
      return 'Utdelning';
    case 'BONUS':
      return 'Bonus';
    case undefined:
      return 'Egentillagd';
    default:
      return 'Utdelning';
  }
}

const MobileLabel = tw.span`block lg:hidden`;

const DividendTable = ({ data, sortOrder }) => {
  const [historicalDividendToggle, setHistoricalDividendToggle] = useState(1);
  const [upcomingDividendToggle, setUpcomingDividendToggle] = useState(1);

  const EventTable = ({ events, sortOrder }) => {
    const sortedEvents = events.sort((a, b) => {
      if (sortOrder === 'ASC') {
        return (
          new Date(a.paymentDate || a.exDate) -
          new Date(b.paymentDate || b.exDate)
        );
      } else if (sortOrder === 'DESC') {
        return (
          new Date(b.paymentDate || b.exDate) -
          new Date(a.paymentDate || a.exDate)
        );
      } else {
        return 0;
      }
    });

    return (
      <>
        {sortedEvents.map((dividend, index) => {
          const item = data.find((i) => i.api_id === dividend.api_id);
          if (!item) return null;
          return (
            <div
              key={index}
              tw="col-span-12 border-gray-200 border-b md:(grid grid-cols-12)"
            >
              <div tw="col-span-12 flex items-center space-x-4 border-gray-200 px-4 py-3 text-left max-lg:(border-b bg-gray-50) lg:(col-span-4 place-content-between)">
                <div tw="flex items-center space-x-1.5">
                  <FlagComponent country={item.country_code} />
                  <span>{item.name}</span>
                </div>
              </div>
              <div tw="col-span-12 flex flex-row place-content-between items-center py-3 px-4 max-lg:border-b lg:(col-span-2 border-l py-3)">
                <MobileLabel>Typ</MobileLabel>
                <span>{getEventType(dividend.dividendType)}</span>
              </div>
              <div tw="col-span-12 flex flex-row place-content-between items-center py-3 px-4 max-lg:border-b lg:(col-span-2 border-l py-3)">
                <MobileLabel>X-dag</MobileLabel>
                <span>
                  {dividend.dividendType !== undefined
                    ? dividend.exDate
                    : "-"}
                </span>
              </div>
              <div tw="col-span-12 flex flex-row place-content-between items-center py-3 px-4 text-right max-lg:border-b lg:(col-span-2 border-l py-3 text-left)">
                <MobileLabel>Utbetalning</MobileLabel>
                <span>
                  {
                    dividend.paymentDate === undefined && dividend.amount === 0 ? "-" :
                      dividend.paymentDate === undefined ? dividend.exDate :
                      dividend.dividendType === undefined ? dividend.exDate : dividend.paymentDate
                  }
                </span>
              </div>
              <div tw="col-span-12 flex flex-row place-content-between items-center py-3 px-4 lg:(col-span-2 border-l py-3)">
                <MobileLabel>Summa</MobileLabel>
                <span>
                  {' '}
                  {formatNumber((
                    dividend.amount *
                    item.quantity *
                    item.currency_multiply
                  ).toFixed(2))}
                  :-
                </span>
              </div>
            </div>
          );
        })}
      </>
    );
  };

  const sortFunction =
    sortOrder === 'ASC'
      ? (a, b) =>
        new Date(a.paymentDate || a.exDate) -
        new Date(b.paymentDate || b.exDate)
      : (a, b) =>
        new Date(b.paymentDate || b.exDate) -
        new Date(a.paymentDate || a.exDate);

  const sortedEvents = sortEvents(data, sortFunction);

  const historicalEvents = sortedEvents.filter(
    (event) =>
      new Date(event.paymentDate || event.exDate) < dayjs().startOf('day')
  );
  const upcomingEvents = sortedEvents.filter(
    (event) =>
      new Date(event.paymentDate || event.exDate) >= dayjs().startOf('day')
  );

  return (
    <div tw="block w-full border-t">
      {sortedEvents.length > 0 ? (
        <>
          <Parent tw="mb-3">
            <Child
              tw="cursor-pointer"
              onClick={() => setUpcomingDividendToggle(!upcomingDividendToggle)}
            >
              <Head tw="flex place-content-between items-center">
                <p>Kommande utdelningar</p>
                <div>
                  {upcomingDividendToggle ? (
                    <img alt="Slå ihop" src={ChevronUp} />
                  ) : (
                    <img alt="Expandera" src={ChevronDown} />
                  )}
                </div>
              </Head>
            </Child>
            <Child css={[upcomingDividendToggle ? tw`block` : tw`hidden`]}>
              <Cell tw="max-lg:hidden">
                <div tw="col-span-4 border-gray-200 border-b py-2 px-4 text-gray-500">
                  Företag
                </div>
                <div tw="col-span-2 border-gray-200 border-b border-l py-2 px-4 text-gray-500">
                  Typ
                </div>
                <div tw="col-span-2 border-gray-200 border-b border-l py-2 px-4 text-gray-500">
                  X-dag
                </div>
                <div tw="col-span-2 border-gray-200 border-b border-l py-2 px-4 text-gray-500">
                  Utbetalas
                </div>
                <div tw="col-span-2 border-gray-200 border-b border-l py-2 px-4 text-gray-500">
                  Summa
                </div>
              </Cell>

              <Cell>
                <EventTable events={upcomingEvents} sortOrder="ASC" />
              </Cell>
            </Child>
          </Parent>
          <Parent tw="mb-4 border-gray-200 border-gray-200 border-t border-b">
            <Child
              tw="cursor-pointer"
              onClick={() =>
                setHistoricalDividendToggle(!historicalDividendToggle)
              }
            >
              <Head tw="flex place-content-between items-center">
                <p>Tidigare utdelningar</p>
                <div>
                  {historicalDividendToggle ? (
                    <img alt="Slå ihop" src={ChevronUp} />
                  ) : (
                    <img alt="Expandera" src={ChevronDown} />
                  )}
                </div>
              </Head>
            </Child>
            <Child css={[historicalDividendToggle ? tw`block` : tw`hidden`]}>
              <Cell tw="max-lg:hidden">
                <div tw="col-span-4 border-gray-200 border-r border-b py-2 px-4 text-gray-500">
                  Företag
                </div>
                <div tw="col-span-2 border-gray-200 border-b border-l py-2 px-4 text-gray-500">
                  Typ
                </div>
                <div tw="col-span-2 border-gray-200 border-b border-l py-2 px-4 text-gray-500">
                  X-dag
                </div>
                <div tw="col-span-2 border-gray-200 border-b border-l py-2 px-4 text-gray-500">
                  Utbetalas
                </div>
                <div tw="col-span-2 border-gray-200 border-b border-l py-2 px-4 text-gray-500">
                  Summa
                </div>
              </Cell>

              <Cell>
                <EventTable events={historicalEvents} sortOrder="DESC" />
              </Cell>
            </Child>
          </Parent>
        </>
      ) : (
        <Parent tw="mb-3">
          <Child>
            <Cell>
              <div tw="col-span-12 flex items-center border-b border-r-0 px-4 py-4 text-left">
                {data.length > 0 ? (
                  <>
                    Kunde inte hitta några utdelningar. Testa att klicka på
                    "Uppdatera aktiekurser" till vänster.
                  </>
                ) : (
                  <>
                    Du behöver lägga till värdeppaper för att kunna se vad
                    bolagen delar ut. Du kan göra det i listan till vänster.
                  </>
                )}
              </div>
            </Cell>
          </Child>
        </Parent>
      )}
    </div>
  );
};

export default DividendTable;
