import React, { useState, useEffect } from 'react';
import { formatNumber } from '../../helpers/index';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

import tw, { styled } from 'twin.macro';

const ChartWrapper = styled.div`
  canvas {
    ${tw`max-w-full`}
  }
`;

ChartJS.register(ArcElement, Tooltip, Legend);

const PortfolioPieChart = ({ portfolio }) => {
  const [sectorsData, setSectorsData] = useState([]);

  useEffect(() => {
    // Calculate total invested money in each sector
    const sectorTotals = {};
    portfolio.forEach((item) => {
      const sector = item.sectors !== undefined ? item.sectors[2] : 'Övrigt';
      sectorTotals[sector] =
        (sectorTotals[sector] || 0) + item.price * item.quantity;
    });
    // Transform the calculated data into an array for the pie chart
    setSectorsData(
      Object.entries(sectorTotals)
        .map(([sector, total]) => ({ sector, total }))
        .sort((a, b) => b.total - a.total)
    );
  }, [portfolio]);

  const data = {
    labels: sectorsData.map(({ sector }) => sector),
    datasets: [
      {
        data: sectorsData.map(({ total }) => total),
        backgroundColor: [
          '#673f95',
          '#713d92',
          '#883786',
          '#9a3378',
          '#a73369',
          '#af375b',
          '#b23f4c',
          '#b2493f',
          '#af5533',
          '#a9602a',
          '#a16c24',
          '#967723',
          '#8a8028',
          '#7d8933',
          '#888f3c',
          '#938733',
          '#9c7e2e',
          '#a5742d',
          '#ac6a30',
          '#b25f36',
          '#b5553e',
          '#b74b48',
          '#b54253',
          '#b13b5f',
          '#a9376c',
          '#9e3678',
          '#8f3884',
          '#7c3c8f',
        ],
      },
    ],
  };

  return (
    <ChartWrapper tw="w-full place-content-center space-y-4 px-16 py-4 text-center max-h-[400px] lg:px-8">
      <p tw="font-bold text-base">Branschfördelning</p>
      <span>Kapital per sektor</span>
      <Pie
        data={data}
        tw="m-auto"
        options={{
          elements: {
            arc: {
              borderWidth: 1,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                label: function (portfolio) {
                  let data = portfolio;
                  let label = portfolio.label || '';
                  let value = portfolio.parsed || '';

                  let total = portfolio.dataset.data.reduce(
                    (partialSum, a) => partialSum + a,
                    0
                  );

                  let percentage = parseFloat(
                    ((value / total) * 100).toFixed(2)
                  );

                  return (
                    formatNumber(value.toFixed(2)) + ':- (' + percentage.toFixed(2) + '%)'
                  );
                },
              },
            },
          },
        }}
      />
    </ChartWrapper>
  );
};

export default PortfolioPieChart;
