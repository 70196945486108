import React from 'react';
import { formatNumber } from '../../helpers/index';

import 'twin.macro';

class PorfolioSum extends React.Component {
  render() {
    return this.props.portfolioSum ? (
      <div tw="flex flex-wrap place-content-center space-y-6 text-center">
        <div tw="block w-full space-y-2">
          <span tw="block text-gray-500">Portföljvärde</span>
          <big tw="block font-sans font-medium text-4xl">
            {formatNumber(Math.round(this.props.portfolioSum))}:-
          </big>
        </div>
        <div tw="block w-full space-y-2">
          <span tw="block text-gray-500">Direktavkastning</span>
          <big tw="block font-sans font-medium text-4xl">{this.props.DA.toFixed(2)}%</big>
        </div>
      </div>
    ) : null;
  }
}

export default PorfolioSum;
