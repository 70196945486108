import pMap from 'p-map';
import { toSek } from '../helpers';

// https://limitless-garden-26844.herokuapp.com/
// https://floating-badlands-53884.herokuapp.com/
// https://limitless-solar-winds.up.railway.app/

const apiUrl =
  'https://limitless-solar-winds-aee5.arvdsn.workers.dev/?https://www.avanza.se/_api/market-guide/stock/';

// -------------------------------
// Add a single stock to portfolio, fetches data from above url
// -------------------------------
const fetchStock = (apiId, options) => {
  return new Promise((resolve, reject) =>
    Promise.all([
      fetch(apiUrl + apiId, options),
      fetch(apiUrl + apiId + '/details', options),
    ]).then(([res, resEvents]) => {
      if (res.ok && resEvents.ok) {
        return Promise.all([res.json(), resEvents.json()]).then(resolve);
      } else {
        return reject(res.error || resEvents.error);
      }
    })
  );
};

export const addStock = (name, apiId) => {
  return (dispatch) => {
    dispatch(dataRequest());

    return fetchStock(apiId)
      .then(([data, dataDetails]) => {
        // set sector if it is exits
        /* const sector =
          (data.sectors &&
            data.sectors[data.sectors.length - 1] &&
            data.sectors[data.sectors.length - 1].sectorName) ||
          'Övrigt'; */

        const sectors = data.sectors.length
          ? data.sectors.map((s) => s.sectorName)
          : ['Övrigt', 'Övrigt', 'Övrigt'];

        // set countryCode
        const countryCode = data.listing.countryCode;

        // set currency multipliers for always show in SEK
        const currency = data.listing.currency;
        const currencyMultiply = toSek(currency);

        // concat arrays
        const dividendList = dataDetails.dividends.events.concat(
          dataDetails.dividends.pastEvents
        );

        // only add current year of dividends
        const dividends = dividendList.filter((el) =>
          el.exDate.includes('2024')
        );

        // set price to two decimals
        const price = data.quote.last * currencyMultiply;
        const priceInt = Number(price.toFixed(2));

        const eventsList =
          dataDetails.companyEvents !== undefined
            ? dataDetails.companyEvents.events
            : [];

        const events = eventsList.filter((el) => el.date.includes('2024'));

        dispatch(
          dataRequestSuccess(
            name,
            countryCode,
            priceInt,
            dividends,
            apiId,
            /* sector, */
            sectors,
            currencyMultiply,
            events
          )
        );
      })
      .catch((error) => {
        console.error(error);
        dispatch(dataRequestFailed('error fetching stock'));
        alert(
          `Kunde inte lägga till ${name}. Detta kan bero på att Avanza har bytt id på värdepappret. Testa att skicka en förfrågan om att lägga till ditt värdepapper genom "Request"-sidan. Du hittar länken ovan.`
        );
      });
  };
};

export const dataRequest = () => {
  return {
    type: 'DATA_STOCK_REQUEST',
  };
};

export const importData = (data, date) => {
  return (dispatch) => {
    dispatch(importDataAction(data, date));
  };
};

export const importDataAction = (data, date) => {
  return {
    type: 'IMPORT_DATA',
    date,
    data,
  };
};

export const dataRequestSuccess = (
  name,
  country_code,
  price,
  dividends,
  api_id,
  /* sector, */
  sectors,
  currency_multiply,
  events
) => {
  // remove object
  const decodedName = name.replace(/&amp;/g, '&');

  return {
    type: 'DATA_STOCK_REQUEST_SUCCESS',
    stock: {
      name: decodedName,
      country_code,
      price,
      dividends,
      api_id,
      quantity: '1',
      value: price,
      /* sector, */
      sectors,
      currency_multiply,
      events,
    },
  };
};

export const dataRequestFailed = (error) => {
  return {
    type: 'DATA_STOCK_REQUEST_FAILED',
    error,
  };
};

export const editStock = (quantity, api_id) => {
  return {
    type: 'EDIT_STOCK',
    quantity,
    api_id,
  };
};

export const deleteStock = (api_id) => {
  return {
    type: 'DELETE_STOCK',
    api_id,
  };
};

// -------------------------------
// Open modal to edit stocks dividend
// -------------------------------
export const editStockModal = (api_id) => {
  return {
    type: 'EDIT_STOCK_MODAL',
    api_id,
  };
};

export const editGav = (api_id, gav) => {
  return {
    type: 'EDIT_GAV',
    api_id,
    gav,
  };
};

export const addDividend = (api_id, exDate, amount) => {
  return {
    type: 'ADD_DIVIDEND',
    api_id,
    newDividend: {
      exDate,
      amount,
    },
  };
};

export const editDividend = (
  api_id,
  exDate,
  amount,
  currency_multiply,
  index
) => {
  const dividendAmount = amount / currency_multiply;
  return {
    type: 'EDIT_DIVIDEND',
    exDate,
    api_id,
    amount: dividendAmount,
    index,
  };
};

export const deleteDividend = (exDate, api_id, index) => {
  return {
    type: 'DELETE_DIVIDEND',
    exDate,
    api_id,
    index,
  };
};

export const closeStockModal = () => {
  return {
    type: 'CLOSE_STOCK_MODAL',
  };
};

// -------------------------------
// This maps through all stocks in portfolio and updates price & value
// -------------------------------

export const updatePortfolio = (portfolio) => {
  return async (dispatch) => {
    dispatch(updateRequest());
    let isFetching = true;
    let error = false;

    await pMap(
      portfolio,
      async (stock) => {
        try {
          const [data, dataDetails] = await fetchStock(stock.api_id, {
            cache: 'reload',
          });
          const countryCode = data.listing.countryCode;

          /* const sector =
            (data.sectors &&
              data.sectors[data.sectors.length - 1] &&
              data.sectors[data.sectors.length - 1].sectorName) ||
            'Övrigt'; */

          // remove sector from local storage
          localStorage.removeItem('sector');

          const sectors = data.sectors.length
            ? data.sectors.map((s) => s.sectorName)
            : ['Övrigt', 'Övrigt', 'Övrigt'];

          // set currency multipliers for always show in SEK
          const currency = data.listing.currency;
          const currencyMultiply = toSek(currency);

          // set price to two decimals
          const price = data.quote.last * currencyMultiply;
          const priceInt = Number(price.toFixed(2));

          // concat arrays
          const dividendList = dataDetails.dividends.events.concat(
            dataDetails.dividends.pastEvents
          );

          // only add current year of dividends
          const dividends = dividendList.filter((el) =>
            el.exDate.includes('2024')
          );

          const eventsList =
            dataDetails.companyEvents !== undefined
              ? dataDetails.companyEvents.events
              : [];

          const events = eventsList.filter((el) => el.date.includes('2024'));

          dispatch(
            updateRequestSuccess(
              priceInt,
              dividends,
              stock.api_id,
              currencyMultiply,
              /* sector, */
              sectors,
              countryCode,
              events
            )
          );
        } catch (err) {
          console.error(err);
          error = true;
          dispatch(updateRequestFailed('error updating stock'));
          alert(
            `Kunde inte uppdatera ${stock.name}. Detta kan bero på att Avanza har bytt id på värdepappret. Testa att skicka en förfrågan om att lägga till ditt värdepapper genom "Request"-sidan. Du hittar länken ovan.`
          );
        }
      },
      { concurrency: 5 }
    );

    isFetching = false;
    return { isFetching, error };
  };
};

export const updateRequest = () => {
  return {
    type: 'UPDATE_PORTFOLIO_REQUEST',
  };
};

export const updateRequestSuccess = (
  price,
  dividends,
  apiId,
  currencyMultiply,
  /* sector, */
  sectors,
  countryCode,
  events
) => {
  return {
    type: 'UPDATE_PORTFOLIO_SUCCESS',
    price,
    dividends,
    api_id: apiId,
    currency_multiply: currencyMultiply,
    /*  sector, */
    sectors,
    country_code: countryCode,
    events,
  };
};

export const updateRequestFailed = (error) => {
  return {
    type: 'UPDATE_PORTFOLIO_FAILED',
    error,
  };
};
