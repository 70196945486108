import React, { useEffect, useState } from 'react';
import axios from 'axios';

import tw from 'twin.macro';
import Plausible from 'plausible-tracker';

import FlagComponent from '../General/Flag';

const Parent = tw.div`flex w-full flex-wrap place-content-start border-gray-200 bg-white lg:border-l [:first-of-type]:(border-l-0 border-t-0)`;
const Child = tw.div`w-full`;
const Cell = tw.div`border-0 border-gray-200 text-center font-mono`;
const Head = tw.div`border-gray-200 border-b bg-gray-50 px-4 py-2.5 font-extrabold`;

const { trackEvent } = Plausible({
  domain: 'aktieutdelningar.now.sh',
  apiHost: 'https://ping.arvdsn.co',
});

function getCountryName(countryCode) {
  switch (countryCode) {
    case 'be':
      return 'Belgien';
    case 'ca':
      return 'Kanada';
    case 'de':
      return 'Tyskland';
    case 'dk':
      return 'Denmark';
    case 'fi':
      return 'Finland';
    case 'fr':
      return 'Frankrike';
    case 'it':
      return 'Italien';
    case 'nl':
      return 'Nederländerna';
    case 'no':
      return 'Norge';
    case 'pt':
      return 'Portugal';
    case 'se':
      return 'Sverige';
    case 'us':
      return 'USA';
    default:
      return countryCode;
  }
}

function getBlogLink(blogName) {
  switch (blogName) {
    case 'Kronan Till Miljonen':
      return 'https://www.kronantillmiljonen.se';
    case 'Dividend Hawk':
      return 'https://dividendhawk.blogspot.com';
    case 'Pappa Betalar?':
      return 'https://pappa-betalar.se';
    case 'Sparo':
      return 'https://sparosverige.blogspot.com';
    case 'The Dividend Story':
      return 'https://thedividendstory.blogspot.com';
    case 'Två Månadslöner':
      return 'https://tvamanadsloner.blogspot.com';
    case 'Dr. Dividend':
      return 'https://www.drdividend.se';
    case 'Rikatillsammans':
      return 'https://rikatillsammans.se';
    case 'Petrusko':
      return 'https://petrusko.blogspot.com';
    case 'Hernhag':
      return 'https://hernhag.se';
    case 'Aktiekemisten':
      return 'https://aktiekemisten.se';
    case 'Att välja lycka':
      return 'https://attvaljalycka.blogspot.com';
    case 'Myntaren':
      return 'https://myntaren.blogspot.com';
    case 'Zero Strategy':
      return 'https://zerostrategy.blogspot.com';
    case 'Krösus Sork':
      return 'https://krosussork.blogspot.com';
    case 'z2036':
      return 'https://z2036.blogspot.com';
    case '10årsplanen':
      return 'https://10arsplanen.blogspot.com';
    case 'Utdelningsstudenten':
      return 'https://utdelningstudent.blogspot.com';
    case 'Utdelningsjägaren':
      return 'https://utdelningsportfolj.blogspot.com';
    case 'Miljonär Innan 30':
      return 'https://tradevenue.se/@miljonarinnan30';
    case 'Aktiepappa':
      return 'https://tradevenue.se/@aktiepappa';
    case 'Sofokles':
      return 'https://tradevenue.se/@fermentumvitae';
    case 'AktieEntreprenören':
      return 'https://tradevenue.se/@aktieentreprenoren';
    case 'Spartacus Invest':
      return 'https://tradevenue.se/@spartacus';
    case 'Bourse':
      return 'https://bourse.se';
    default:
      return null;
  }
}

const Main = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [stats, setStats] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get(
          'https://aktieutdelningar-g3zg0.ams3.digitaloceanspaces.com/stats.json'
        );

        const stats = data.data[1];
        setStats(stats);
        setIsLoading(false);
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, []);

  function getStatsEntries(data, stats, dataTree) {
    const totalVisitors = data
      .filter((entry) => entry[dataTree] !== '(none)')
      .reduce((acc, curr) => acc + curr.visitors, 0);

    return data
      .filter((entry) => entry[dataTree] !== '(none)')
      .map((entry, index) => {
        const percentage = ((entry.visitors / totalVisitors) * 100).toFixed(2);

        return (
          <div
            key={index}
            tw="flex place-content-between border-gray-200 border-b border-l border-l-0 px-4 py-2.5"
          >
            <div tw="block flex items-center space-x-1">
              {dataTree === 'country' ? (
                <>
                  <FlagComponent country={entry[dataTree].toUpperCase()} />
                  <span>{getCountryName(entry[dataTree])}</span>
                </>
              ) : dataTree === 'blog' &&
                getBlogLink(entry[dataTree]) !== null ? (
                <>
                  <span>
                    <a
                      tw="font-semibold text-blue-600"
                      href={getBlogLink(entry[dataTree])}
                      target="_blank"
                      rel="noopener"
                      onClick={() => {
                        trackEvent('Blog: Outbound', {
                          props: {
                            blog: entry[dataTree],
                            url: getBlogLink(entry[dataTree]),
                          },
                        });
                      }}
                    >
                      {entry[dataTree]}
                    </a>
                  </span>
                </>
              ) : (
                entry[dataTree]
              )}
            </div>
            <span>
              {dataTree === 'country' ? (
                <>{percentage}%</>
              ) : (
                <>{entry.visitors}</>
              )}
            </span>
          </div>
        );
      });
  }

  return (
    <div tw="flex flex-auto h-full w-full flex-wrap overflow-y-auto bg-gray-100 max-md:pb-[24px] lg:block">
      {isLoading ? (
        <Parent tw="border-gray-200 border-t">
          <Child>
            <Cell>
              <div tw="col-span-12 flex items-center border-b px-4 py-4 text-left">
                Laddar statistik...
              </div>
            </Cell>
          </Child>
        </Parent>
      ) : (
        <>
          <Parent>
            <Child>
              <Head>Topplista (30 dagar)</Head>
            </Child>
          </Parent>
          <div tw="grid w-full grid-cols-1 max-md:pb-[68px] lg:grid-cols-3">
            <Parent>
              <Child>
                <Head tw="flex place-content-between items-center font-semibold">
                  <span>Bloggare</span>
                  <span>Besökare</span>
                </Head>
                <Cell>{getStatsEntries(stats.blogStats, stats, 'blog')}</Cell>
              </Child>
            </Parent>
            <Parent>
              <Child>
                <Head tw="flex place-content-between items-center font-semibold">
                  <span>Värdepapper</span>
                  <span>Antal</span>
                </Head>
                <Cell>{getStatsEntries(stats.stockStats, stats, 'name')}</Cell>
              </Child>
            </Parent>
            <Parent>
              <Child>
                <Head tw="flex place-content-between items-center font-semibold">
                  <span>Länder</span>
                  <span>Andel</span>
                </Head>
                <Cell>
                  {getStatsEntries(stats.countryStats, stats, 'country')}
                </Cell>
              </Child>
            </Parent>
          </div>
        </>
      )}
    </div>
  );
};

export default Main;
