import React, { useState } from 'react';
import HCaptcha from '@hcaptcha/react-hcaptcha';

import 'twin.macro';

import { Helmet } from 'react-helmet';

import Layout from '../components/Layout';
import Message from '../components/Message';

const RequestStock = ({ header }) => {
  const [formData, setFormData] = useState({
    name: '',
    id: '',
    hCaptcha: '',
  });
  const [lastSubmission, setLastSubmission] = useState(null);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    if (e.target.name === 'id') {
      if (/^\d+$/.test(e.target.value)) {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        });
      }
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.hCaptcha) {
      setError(
        'Du behöver acceptera Captcha innan du kan skicka iväg formuläret.'
      );
      return;
    }
    if (!formData.name || !formData.id) {
      setError('Fyll i alla fält innan du skickar.');
      return;
    }
    if (lastSubmission && Date.now() - lastSubmission < 5 * 60 * 1000) {
      setError('Du måste vänta lite till innan du kan skicka iväg formuläret.');
      return;
    }
    setError(null);

    try {
      const response = await fetch(
        'https://limitless-solar-winds.up.railway.app/https://a2nrbd7miu5j2ev.fsn.arvdsn.co/webhook/6683eb43-328c-41ef-a3f3-30a96a60d3c1',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization:
              'Basic ' +
              btoa(
                '2ki!wuFNP4Ed4#hJ&*X$^Q6bDAdpvMCy:i^S^dt5mi2Mp^yhfk4cV8xdjKJNgyc&Z'
              ),
          },
          body: JSON.stringify(formData),
        }
      );
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      setLastSubmission(Date.now());
      setFormData({ name: '', id: '', hCaptcha: '' });
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <>
      <Layout>
        <main className="content" tw="m-auto mt-0 max-w-xl space-y-3 text-base">
          {header && (
            <>
              <p tw="mb-8">
                <a href="/">Tillbaka till Aktieutdelningar</a>
              </p>
              <h1 tw="pb-2">
                <span role="img" aria-label="emoji">
                  ⚡️
                </span>
                <span>Rapportera saknat värdepapper</span>
              </h1>
            </>
          )}
          {!header && <h3>Rapportera saknat värdepapper</h3>}
          <p>
            Här kan du fylla i formuläret nedan och klicka på skicka så
            informeras vi direkt om det saknade värdepappret och kan enklare
            lägga till det åt dig.
          </p>
          <p>
            För att kunna skicka så behöver du <strong>namn</strong> och{' '}
            <strong>id</strong>. Id är Avanzas identifikationsnummer för
            värdepappret och det hittar du i addressen till
            värdepappershemsidan.
          </p>
          <Message type="info">
            Notera att det <strong>inte</strong> är ISIN-nummer du ska fylla i
            här, utan Avanzas id som du hittar i addressen.
          </Message>
          <p>
            Här är ett exempel för <strong>Investor B</strong> där id är{' '}
            <strong>5247</strong>:
            <br />
            <em>https://www.avanza.se/aktier/om-aktien.html/5247/investor-b</em>
          </p>
          <p>
            Värdepappret behöver fortfarande läggas till av oss manuellt, så om
            formuläret missbrukas kommer vi att ta bort det igen.
          </p>

          <form tw="pt-4" onSubmit={handleSubmit}>
            <label>
              Namn:
              <input
                tw="w-64"
                type="text"
                placeholder="Namn på värdepapper"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
            </label>
            <label>
              Id hos Avanza:
              <input
                tw="w-64"
                type="text"
                placeholder="000000"
                name="id"
                value={formData.id}
                onChange={handleChange}
              />
            </label>
            <br />
            <HCaptcha
              sitekey="b4c52027-0db6-4145-a3c8-73348fcc571f"
              onVerify={(token) => {
                setFormData({
                  ...formData,
                  hCaptcha: token,
                });
              }}
            />
            {error && (
              <>
                <br />
                <Message type="error">{error}</Message>
              </>
            )}
            {lastSubmission && (
              <>
                <br />
                <Message type="success">
                  Din förfrågan har skickats. Det kan dröja 1-2 minuter innan
                  ditt värdepapper dyker upp i listan.
                </Message>
              </>
            )}
            <button tw="mt-4" className="primary" type="submit">
              Skicka
            </button>
          </form>
        </main>
      </Layout>
    </>
  );
};

export default RequestStock;
