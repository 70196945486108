import React from 'react';
import { connect } from 'react-redux';
import { formatNumber } from '../../helpers/index';

import tw from 'twin.macro';

import { getMonthList } from '../../helpers';

const Parent = tw.div`grid w-full grid-cols-1 border-gray-200 bg-white 2xl:grid-cols-12`;
const Child = tw.div`w-full border-gray-200 border-t border-b`;
const Cell = tw.div`flex h-full flex-row-reverse place-content-between items-center border-gray-200 border-r-0 border-b px-4 text-center xl:items-start 2xl:border-r`;
const Head = tw.div`col-span-12 bg-gray-50 py-2.5 text-center font-extrabold`;
const ChildGrow = tw.div`flex-1`;
const MonthSpan = tw.span`text-xs text-gray-600 2xl:hidden`;
const StockList = tw.ul`w-full space-y-1 break-all py-4 text-left font-mono lg:space-y-4 2xl:text-center`;

class StockTable extends React.PureComponent {
  getList = (month) => {
    if (Object.keys(this.props.portfolio).length !== 0) {
      const list = getMonthList(this.props.portfolio, month);
      if (list != null) {
        return list.stocks.map((item, i) => (
          <li tw="m-0 text-left 2xl:(space-y-1 text-center)" key={i}>
            <div tw="inline-block text-gray-500 text-xs md:block">
              {item.name}
            </div>{' '}
            <div tw="m-0 inline-block text-xs md:block 2xl:text-sm">
              {formatNumber(parseFloat(list.dividendArray[i].toFixed(2)))}:-
            </div>
          </li>
        ));
      }
    }
    return '-';
  };

  renderMonths() {
    const months = ["Jan", "Feb", "Mar", "Apr", "Maj", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dec"];
    const year = "2024";
    return months.map((month, i) => (
      <ChildGrow key={i}>
        <Cell>
          <MonthSpan>{month}</MonthSpan>
          <StockList>{this.getList(`${year}-${(i + 1).toString().padStart(2, '0')}`)}</StockList>
        </Cell>
      </ChildGrow>
    ));
  }

  render() {
    return (
      <div tw="w-full">
        <Parent>
          <Child tw="col-span-12">
            <Head>
              <p>Bolagens utdelningar</p>
            </Head>
          </Child>
        </Parent>
        <Parent tw="mb-3">
          {this.renderMonths()}
        </Parent>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    portfolio: state.portfolioReducer.portfolio,
  };
}

export default connect(mapStateToProps)(StockTable);
