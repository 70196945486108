import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import 'twin.macro';

import { Helmet } from 'react-helmet';
import Message from '../components/Message';

import { importData } from '../actions/stockActions';

const ImportData = ({ header, importData, state: data }) => {
  const fileInput = useRef(null);
  const [message, setMessage] = useState('');

  const handleUpload = () => {
    fileInput.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      try {
        const data = JSON.parse(event.target.result);
        importData(data, new Date().toISOString());
        setMessage('success');
      } catch (error) {
        setMessage('failed');
      }
    };

    reader.readAsText(file);
  };

  return (
    <>
      <main className="content" tw="m-auto mt-0 max-w-xl space-y-3 text-base">
        {header && (
          <>
            <p tw="mb-8">
              <a href="/">Tillbaka till Aktieutdelningar</a>
            </p>
            <h1 tw="pb-2">
              <span role="img" aria-label="emoji">
                🪴
              </span>
              <span>Importera data</span>
            </h1>
          </>
        )}
        {!header && <h3>Importera data</h3>}
        <p>
          Välj en fil och klicka sedan på ladda upp. Notera att{' '}
          <strong>all befintlig data</strong> kommer att skrivas över. Vill du
          spara något så bör du göra det först.
        </p>
        <p tw="mb-2!">
          Vi har tyvärr inte möjlighet att hjälpa dig återställa något eftersom
          allt enbart finns i din webbläsare. Använd funktionen under eget
          bevåg.
        </p>
        <Message type="info">
          Notera att filen du laddar upp behöver vara i <em>.json</em>-format,
          och förslagsvis genererad från vår export-funktion. Importerar du en
          felaktig fil kommer systemet neka din import och lämna datan tom.
        </Message>
        {message === 'success' && (
          <Message type="success">Filen importerades utan problem.</Message>
        )}
        {message === 'failed' && (
          <Message type="error">
            Något gick fel med importen. Har du justerat innehållet?
          </Message>
        )}
        <input
          type="file"
          ref={fileInput}
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
        <button className="secondary" onClick={handleUpload}>
          Importera fil
        </button>
      </main>
    </>
  );
};

export default connect(
  (state) => ({ state: { portfolioReducer: state.portfolioReducer } }),
  { importData }
)(ImportData);
