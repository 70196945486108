import React, { useState } from 'react';
import tw, { styled } from 'twin.macro';

import { Helmet } from 'react-helmet';

import Layout from '../components/Layout';
import ExportData from '../views/ExportData';
import ImportData from '../views/ImportData';
import RequestStock from '../views/RequestStock';

const title = 'Verktyg | Aktieutdelningar';
const desc =
      'Behöver du anmäla ett saknat värdepapper? Exportera och importera ditt innehav? Du hittar smarta verktyg här som gör plattformen enklare att använda.';

const Tools = () => {
  const [currentTab, setCurrentTab] = useState(1);

  const Button = styled.button(({ isCurrent }) => [
    tw`bg-gray-200 px-3.5 text-sm`,
    isCurrent && tw`bg-black text-white`,
  ]);

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={desc} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={desc} />
        <meta property="og:url" content={window.location.href} />
        {/* <meta property="og:image" content={NextoryMetaImg} /> */}
        <meta name="twitter:title" content={title} />
      </Helmet>
      <Layout>
        <main tw="mt-0 h-full w-full overflow-y-auto py-8 pb-24 max-md:px-4">
          <div className="content" tw="m-auto max-w-xl space-y-3 text-base">
            <p tw="mb-8">
              <a href="/">Tillbaka till Aktieutdelningar</a>
            </p>
            <h1 tw="pb-2">
              <span role="img" aria-label="emoji">
                🪓
              </span>
              <span>Verktyg</span>
            </h1>
            <p>
              Då funktionaliteten är ganska begränsad i denna appen har vi
              skapat ett par verktyg som gör det enklare att hantera datan vi
              sparar och läser in.
            </p>
            <div tw="py-4">
              <div tw="mb-4 space-x-2">
                <Button
                  isCurrent={currentTab === 1}
                  onClick={() => setCurrentTab(1)}
                >
                  Request
                </Button>
                <Button
                  isCurrent={currentTab === 2}
                  onClick={() => setCurrentTab(2)}
                >
                  Export
                </Button>
                <Button
                  isCurrent={currentTab === 3}
                  onClick={() => setCurrentTab(3)}
                >
                  Import
                </Button>
              </div>
              <div tw="rounded border border-gray-200 p-4">
                {currentTab === 1 && <RequestStock header={false} />}
                {currentTab === 2 && <ExportData header={false} />}
                {currentTab === 3 && <ImportData header={false} />}
              </div>
            </div>
          </div>
        </main>
      </Layout>
    </>
  );
};

export default Tools;
