import React from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter, Routes, Route, Navigate, Link } from 'react-router-dom';

import { Global } from '@emotion/react';
import tw, { css } from 'twin.macro';

import Footer from './components/Footer';
import Header from './components/Header';
import Navbar from './components/Navbar';

import Blogs from './views/Blogs';
import Holdings from './views/Holdings';
import Overview from './views/Overview';
import Reports from './views/Reports';
import Statistics from './views/Statistics';

import EditDividend from './components/EditDividend';
import Donate from './views/Donate';
import FAQ from './views/FAQ';
import News from './views/News';
import Tools from './views/Tools';

const App = () => {
  return (
    <BrowserRouter>
      <Global
        styles={css`
          html {
            ${tw`fixed inset-0 overflow-hidden`}
            font-size: 95%;
          }

          body {
            ${tw`fixed inset-0 block h-screen overflow-hidden bg-white text-sm antialiased`}
          }

          #root {
            ${tw`flex h-full flex-col`}
          }

          .content {
            h1 {
              ${tw`space-x-2 font-bold text-2xl`}
            }

            h2 {
              ${tw`block font-bold text-lg`}
            }

            h3 {
              ${tw`block font-bold text-lg`}
            }

            h4 {
              ${tw`block font-bold text-base`}
            }

            p {
              ${tw`text-base`}
            }

            a {
              ${tw`font-semibold text-blue-600 transition hover:text-blue-700`}
            }

            ul {
              ${tw`ml-8 list-disc`}
            }

            input[type='text'] {
              ${tw`my-2`}
            }
          }

          label {
            ${tw`block`}
          }

          input[type='text'],
          input[type='number'],
          input[type='date'],
          textarea {
            ${tw`block rounded border border-gray-300 p-3 transition`}

            &.disabled,
            &[disabled] {
              ${tw`cursor-not-allowed bg-gray-100 text-gray-500`}
            }
          }

          input[type='number'] {
            &::-webkit-inner-spin-button,
            &::-webkit-outer-spin-button {
              display: none;
              -webkit-appearance: none;
              margin: 0;
            }
          }

          button {
            ${tw`rounded py-3 px-4 transition`}

            &.primary {
              ${tw`bg-blue-500 text-white hover:bg-blue-600`}
            }

            &.secondary {
              ${tw`bg-gray-200 text-black hover:bg-gray-300`}
            }

            &.error,
            &.destructive {
              ${tw`bg-red-500 text-white hover:bg-red-600`}
            }

            &.disabled,
            &[disabled] {
              ${tw`cursor-not-allowed bg-gray-300 text-gray-500 hover:bg-gray-300`}
            }
          }
        `}
      />
      <Header />
      <Routes>
        <Route exact path="/" element={<Overview />} />

        {/* Main pages */}
        <Route path="/overview" element={<Navigate to="/" replace />} />
        <Route path="/holdings" element={<Holdings />} />
        <Route path="/reports" element={<Reports />} />
        <Route path="/toplist" element={<Statistics />} />
        <Route path="/blogs" element={<Blogs />} />

        {/* Extra */}
        <Route path="/tools" element={<Tools />} />
        <Route path="/news" element={<News />} />
        <Route path="/faq" element={<FAQ />} />

        {/* Catch-all */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <Navbar />
      <Footer />
      <EditDividend />
    </BrowserRouter>
  );
};

export default App;
