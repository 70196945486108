import React from 'react';
import { formatNumber } from '../../helpers/index';
import { connect } from 'react-redux';
import tw, { styled } from 'twin.macro';

const Parent = tw.div`mb-3 grid w-full border-gray-200 border-b bg-white lg:grid-cols-3`;
const Child = tw.div`flex flex-col`;
const Head = tw.div`border-gray-200 border-t border-b bg-gray-50 py-2.5 text-center font-extrabold xl:border-r`;
const Content = tw.div`flex h-full place-content-center items-center border-gray-200 py-4 text-center font-mono text-3xl xl:border-r`;
const DL = styled.dl`
  ${tw`flex flex-row flex-wrap text-sm`}
  dt {
    ${tw`w-1/2 text-right font-sans text-gray-500`}
  }
  dd {
    ${tw`w-1/2 text-left`}
  }
`;

class DividendSumTable extends React.PureComponent {
  render() {
    return (
      <Parent>
        <Child>
          <Head>
            <p>Totalt utdelningar 2024</p>
          </Head>
          <Content>{formatNumber(this.props.year)}:-</Content>
        </Child>
        <Child>
          <Head>Snitt i månaden</Head>
          <Content>{formatNumber(Math.round(this.props.year / 12))}:-</Content>
        </Child>
        <Child>
          <Head tw="lg:border-r-0">Snitt per...</Head>
          <Content tw="lg:border-r-0">
            <DL>
              <dt>Kvartal:&nbsp;</dt>
              <dd>
                {formatNumber((this.props.year / 4).toFixed(2))}
                :-
              </dd>
              <dt>Dag:&nbsp;</dt>
              <dd>
                {formatNumber((this.props.year / 365).toFixed(2))}
                :-
              </dd>
              <dt>Timme:&nbsp;</dt>
              <dd>
                {formatNumber((this.props.year / 8760).toFixed(4))}
                :-
              </dd>
              <dt>Minut:&nbsp;</dt>
              <dd>
                {formatNumber((this.props.year / 525600).toFixed(6))}
                :-
              </dd>
            </DL>
          </Content>
        </Child>
      </Parent>
    );
  }
}

function mapStateToProps(state) {
  return {
    portfolio: state.portfolioReducer.portfolio,
  };
}

export default connect(mapStateToProps)(DividendSumTable);
