import React from 'react';
import { formatNumber } from '../../helpers/index';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

import tw, { styled } from 'twin.macro';

const ChartWrapper = styled.div`
  canvas {
    ${tw`max-w-full`}
  }
`;

ChartJS.register(ArcElement, Tooltip, Legend);

const PortfolioPie = ({ portfolio }) => {
  const data = portfolio.reduce((acc, cur) => {
    const { name, price, quantity } = cur;
    acc[name] = (acc[name] || 0) + price * quantity;
    return acc;
  }, {});

  const chartData = {
    labels: Object.keys(data),
    datasets: [
      {
        data: Object.values(data),
        backgroundColor: [
          '#673f95',
          '#713d92',
          '#793b8e',
          '#81398a',
          '#883786',
          '#8f3581',
          '#95347d',
          '#9a3378',
          '#9f3273',
          '#a3326e',
          '#a73369',
          '#aa3364',
          '#ac355f',
          '#af375b',
          '#b03956',
          '#b23c51',
          '#b23f4c',
          '#b34248',
          '#b34643',
          '#b2493f',
          '#b24d3b',
          '#b15137',
          '#af5533',
          '#ad5930',
          '#ab5d2d',
          '#a9602a',
          '#a76427',
          '#a46825',
          '#a16c24',
          '#9d6f23',
          '#9a7322',
          '#967723',
          '#927a24',
          '#8e7d26',
          '#8a8028',
          '#86832b',
          '#81872f',
          '#7d8933',
          '#788c37',
          '#738f3c',
          '#888f3c',
          '#8c8c39',
          '#8f8936',
          '#938733',
          '#968431',
          '#99812f',
          '#9c7e2e',
          '#9f7a2d',
          '#a2772d',
          '#a5742d',
          '#a8712e',
          '#aa6d2f',
          '#ac6a30',
          '#ae6632',
          '#b06334',
          '#b25f36',
          '#b35c38',
          '#b4583b',
          '#b5553e',
          '#b65241',
          '#b64e45',
          '#b74b48',
          '#b7484c',
          '#b6454f',
          '#b54253',
          '#b43f57',
          '#b33d5b',
          '#b13b5f',
          '#af3963',
          '#ac3868',
          '#a9376c',
          '#a63670',
          '#a23674',
          '#9e3678',
          '#9a367c',
          '#953780',
          '#8f3884',
          '#893988',
          '#833a8c',
          '#7c3c8f',
        ],
      },
    ],
  };

  return (
    <ChartWrapper tw="space-y-4 px-16 py-4 text-center max-h-[400px] lg:px-8">
      <p tw="font-bold text-base">Portföljfördelning</p>
      <span>Kapital per bolag</span>

      <Pie
        data={chartData}
        tw="m-auto"
        options={{
          elements: {
            arc: {
              borderWidth: 1,
            },
          },
          plugins: {
            legend: {
              display: false,
            },

            tooltip: {
              callbacks: {
                label: function (portfolio) {
                  let data = portfolio;
                  let label = portfolio.label || '';
                  let value = portfolio.parsed || '';

                  let total = portfolio.dataset.data.reduce(
                    (partialSum, a) => partialSum + a,
                    0
                  );

                  let percentage = parseFloat(
                    ((value / total) * 100).toFixed(2)
                  );

                  return (
                    formatNumber(value.toFixed(2)) + ':- (' + percentage.toFixed(2) + '%)'
                  );
                },
              },
            },
          },
        }}
      />
    </ChartWrapper>
  );
};

export default PortfolioPie;
